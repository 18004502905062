import {createAsyncThunk} from '@reduxjs/toolkit'
import {baseApi} from '../../../baseAPI'

export const fetchDashboard = createAsyncThunk('dashboard/fetchDashboard', async () => {
  const response = await baseApi.get(`dashboard/count`)
  return response.data
})

export const fetchHeaders = createAsyncThunk('dashboard/fetchHeaders', async (paramId: any) => {
  const response = await baseApi.get(`dashboard/headers?dashboardId=${paramId}`)
  return {...response.data}
})

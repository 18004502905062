import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {baseApi} from '../../../baseAPI'

export const fetchData = createAsyncThunk('data-scan/fetchData', async (params: any) => {
  // console.log('fetchdata log', params)
  const response = await baseApi.post(`data/scan/record`, params)
  return {...response.data, ...params}
})
export const fetchFilters = createAsyncThunk('data-scan/fetchFilters', async () => {
  // console.log('fetchdata log', params)
  const response = await baseApi.get(`data/scan/filters`)
  return {...response.data}
})


import React from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {AuthPage} from '../modules/auth'
import {ForgotPassword} from '../modules/auth/components/ForgotPassword'
import {Login} from '../modules/auth/components/Login'
import {Registration} from '../modules/auth/components/Registration'

export function PublicRoutes() {
  return (
    <Routes>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      {/* <Navigate from='/auth' exact={true} to='/auth/login' /> */}
      <Route path='/' element={<Navigate to='/auth/login' />} />
      <Route path='*' element={<Navigate to='/auth/login' />} />
    </Routes>
  )
}

import {createSlice} from '@reduxjs/toolkit'
import {toastService} from '../../../Components/Toast'
import {fetchData, fetchFilters} from './APIS'

const initialState = {
  loading: false,
  data: [],
  error: '',
  recordCount: 0,
  filters: [],
}

const projectScanSlice = createSlice({
  name: 'project-scan',
  initialState,
  reducers: {
    removeItems: (state) => {
      state.data = []
      state.recordCount = 0
    },
    removeHeaders: (state) => {
      state.filters = []
    },
  },
  extraReducers: {
    [fetchData.pending]: (state, payload) => {
      // console.log('pending')
      return {
        ...state,
        loading: true,
      }
    },
    [fetchData.fulfilled]: (state, {payload}) => {
      return {
        ...state,
        data: payload.offset === 0 ? payload.data : [...state.data, ...payload.data],
        loading: false,
        recordCount: payload.recordCount,
      }
    },
    [fetchData.rejected]: (state, {payload}) => {
      // console.log('rejected')
      return {...state, loading: false, recordCount: undefined}
    },
    [fetchFilters.pending]: (state, payload) => {
      // console.log('pending')
      return {
        ...state,
      }
    },
    [fetchFilters.fulfilled]: (state, {payload}) => {
      return {
        ...state,
        filters: payload.data,
      }
    },
    [fetchFilters.rejected]: (state, {payload}) => {
      // console.log('rejected')
      return {...state}
    },
  },
})
export const {removeItems, removeHeaders} = projectScanSlice.actions
export default projectScanSlice.reducer

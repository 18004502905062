/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as auth from '../redux/AuthRedux'
import {login} from '../redux/AuthCRUD'
import './styles/styles.css'
import TextField from '@mui/material/TextField'
import {toastService} from '../../../Components/Toast'

// const loginSchema = Yup.object().shape({
//   email: Yup.string()
//     .email('Wrong email format')
//     .min(3, 'Minimum 3 symbols')
//     .max(50, 'Maximum 50 symbols')
//     .required('Email is required'),
//   password: Yup.string()
//     .min(3, 'Minimum 3 symbols')
//     .max(50, 'Maximum 50 symbols')
//     .required('Password is required'),
// })

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({userName: '', password: ''})
  const dispatch = useDispatch()

  const handleSubmit = (e: any) => {
    e.preventDefault()
    setLoading(true)
    login(data.userName, data.password)
      .then((res) => {
        setLoading(false)
        if (res.status === 200) {
          dispatch(auth.actions.login(res.headers.get('auth-token')))
          res.json().then((user) => {
            dispatch(auth.actions.setUser(user.data[0]))
          })
        } else {
          res.json().then((error) => {
            toastService.error(error.message || 'Something went wrong!')
          })
        }
      })
      .catch((error) => {
        toastService.error(error || 'Something went wrong!')
        setLoading(false)
      })
  }

  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: loginSchema,
  //   onSubmit: (values, {setStatus, setSubmitting}) => {
  //     // setLoading(true)
  //     setTimeout(() => {
  //       // login(data.userName, data.password)
  //       //   .then((res)=> {
  //       //     setLoading(false)
  //       //   .catch(() => {
  //       //     setLoading(false)
  //       //     setSubmitting(false)
  //       //     setStatus('The login detail is incorrect')
  //       //   })
  //     }, 2000)
  //   },
  // })

  return (
    <>
      <form className='form w-100' onSubmit={handleSubmit} noValidate id='kt_login_signin_form'>
        <div className='d-flex text-center justify-content-center w-100 my-5'>
          <h1 className='w-50 mt-5 text-bold'>Sign in</h1>
        </div>
        <div className='d-flex justify-content-center w-100 mt-3'>
          <img
            className='w-25 sub-logo'
            src='https://pclawtimematters.com/img/png/logo-primary-rgb.png'
            alt='PCLaw | Time Matters'
          />
        </div>
        <div className='form-input d-flex align-items-center flex-column w-100 my-5'>
          <TextField
            type='email'
            size='medium'
            className='mb-3 w-60 input'
            label='Email'
            id='custom-css-outlined-input-1'
            color='error'
            value={data.userName}
            onChange={(e) => setData({...data, userName: e.target.value})}
          />
          <TextField
            type='password'
            size='medium'
            className='mb-3 w-60 input'
            label='Password'
            id='custom-css-outlined-input-2'
            color='error'
            value={data.password}
            onChange={(e) => setData({...data, password: e.target.value})}
          />

          <div className='d-flex text-center justify-content-center w-100 mt-1'>
            {loading ? (
              <button className='btn btn-custom theme-color w-60' type='submit' disabled={loading}>
                Login In...
                <span
                  className='spinner-border mx-5 spinner-border-sm'
                  role='status'
                  aria-hidden='true'
                ></span>
              </button>
            ) : (
              <button disabled={loading} type='submit' className='btn btn-custom theme-color w-60'>
                Login
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  )
}

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {baseApi} from '../../../baseAPI'
import {toastService} from '../../../Components/Toast'

const initialState = {
  loading: false,
  data: [],
  syncDataData: false,
  caseCreateLoading: false,
  salesForceURLId: undefined,
  filtersFetchLoading: false,
  filtersValueFetchLoading: false,
  filtersFetchData: [],
  filtersValueFetchData: [],
  addUpdateLoading: false,
  error: '',
  recordCount: undefined,
}

// const location = window.location.hostname;

export const fetchData = createAsyncThunk('new-installation/fetchData', async (params) => {
  // console.log('fetchdata log', params)
  const response = await baseApi.post(`installations/data`, params)
  return {...response.data, ...params}
})

export const syncData = createAsyncThunk('new-installation/syncData', async () => {
  const response = await baseApi.get(`installations/sync/data`)
  return response.data
})

export const salesforceCaseUrl = createAsyncThunk(
  'new-installation/salesforceCaseUrl',
  async (params) => {
    const response = await baseApi.get(`create/salesforce/case?syncId=${params.id}`)
    return response.data
  }
)

export const filtersFetch = createAsyncThunk('new-installation/filtersFetch', async () => {
  const response = await baseApi.get(`installations/filters`)
  return response.data
})

export const filtersValueFetch = createAsyncThunk(
  'new-installation/filtersValueFetch',
  async (params: any) => {
    const response = await baseApi.get(`installations/filter/values`, {params: params})
    return response.data
  }
)

const newInstallationSlice = createSlice({
  name: 'new-installation',
  initialState,
  reducers: {
    removeItems: (state) => {
      state.data = []
      state.removeItems = 0
    },
    removeValueData: (state) => {
      state.filtersValueFetchData = []
    },
  },
  extraReducers: {
    [fetchData.pending]: (state, payload) => {
      // console.log('pending')
      return {
        ...state,
        loading: true,
      }
    },
    [fetchData.fulfilled]: (state, {payload}) => {
      return {
        ...state,
        data: payload.offset === 0 ? payload.data : [...state.data, ...payload.data],
        loading: false,
        recordCount: payload.recordCount,
      }
    },
    [fetchData.rejected]: (state, {payload}) => {
      // console.log('rejected')
      return {...state, loading: false, recordCount: undefined}
    },
    [syncData.pending]: (state) => {
      return {...state, syncDataData: true}
    },
    [syncData.fulfilled]: (state, {payload}) => {
      return {...state, syncDataData: false}
    },
    [syncData.rejected]: (state, {payload}) => {
      return {...state, syncDataData: false}
      // return {...state, error: payload, loading: false}
    },
    [salesforceCaseUrl.pending]: (state, payload) => {
      return {
        ...state,
        caseCreateLoading: true,
        salesForceURLId: payload.meta.arg.id,
      }
    },
    [salesforceCaseUrl.fulfilled]: (state, {payload}) => {
      toastService.success('Success')
      return {...state, caseCreateLoading: false, salesForceURLId: undefined}
    },
    [salesforceCaseUrl.rejected]: (state, {payload}) => {
      toastService.error('Failed')
      return {...state, caseCreateLoading: false, salesForceURLId: undefined}
    },
    [filtersFetch.pending]: (state) => {
      return {...state}
    },
    [filtersFetch.fulfilled]: (state, {payload}) => {
      return {...state, filtersFetchData: payload.data}
    },
    [filtersFetch.rejected]: (state, {payload}) => {
      return {...state}
    },
    [filtersValueFetch.pending]: (state) => {
      return {...state, filtersValueFetchLoading: true}
    },
    [filtersValueFetch.fulfilled]: (state, {payload}) => {
      return {
        ...state,
        filtersValueFetchLoading: false,
        filtersValueFetchData: [...state.filtersValueFetchData, ...payload.data],
      }
    },
    [filtersFetch.rejected]: (state, {payload}) => {
      return {...state, filtersValueFetchLoading: false}
    },
  },
})
export const {removeItems, removeValueData} = newInstallationSlice.actions
export default newInstallationSlice.reducer

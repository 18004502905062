/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC} from 'react'
import {Navigate, Routes, Route} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout, AuthPage} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'
import {PublicRoutes} from './PublicRoutes'

const RoutesMain: FC = () => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)

  return (
    <Routes>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/

        <Route element={<AuthPage />}>
          <Route path='auth/*' element={<PublicRoutes />} />
        </Route>
      ) : (
        /*Otherwise Navigate to root page (`/`)*/
        <Route element={<Navigate to='/dashboard' />} />
      )}

      <Route path='/error' element={<ErrorsPage />} />
      <Route path='/logout' element={<Logout />} />
      <Route path='' element={<Navigate to='/auth/login' />} />

      {!isAuthorized ? (
        /*Navigate to `/auth` when user is not authorized*/
        <Route element={<Navigate to='/auth/login' />} />
      ) : (
        <Route element={<MasterLayout />}>
          <Route path='/*' element={<PrivateRoutes />} />
        </Route>
      )}
    </Routes>
  )
}

export {RoutesMain}

import * as React from 'react'

import {CoreToastContainer, CoreToast} from '../Core/Toast'

// import SuccessImage from 'app/Components/Toast/assets/SuccessImage.png';
// import ErrorImage from 'app/Components/Toast/assets/ErrorImage.png';
// import WarningImage from 'app/Components/Toast/assets/WarningImage.png';
// import { ReactComponent as CloseIcon } from 'app/Components/Toast/assets/close.svg';

import './style.css'

interface toastProps {}

export const Toast: React.FC<toastProps> = (props) => {
  return (
    <CoreToastContainer
      containerId='toastContainer'
      // closeButton={CloseButton}
      draggable={false}
      hideProgressBar={true}
      theme='colored'
    />
  )
}

// const CloseButton = ({ closeToast }) => (
//   <div>
//     <CloseIcon className="custom-text-secondary" onClick={closeToast} />
//   </div>
// );

// const getImage = (file, alt) => {
//   return <img src={file} height="20" width="20" alt={alt} />;
// };

// const ToastImage: React.FC<any> = props => {
//   const { file, alt } = props;
//   return getImage(file, alt);
// };

const toastService = {
  success(message: any) {
    CoreToast.success(message, {
      // icon: <ToastImage file={SuccessImage} alt="success" />,
      autoClose: 3000,
    })
  },
  error(message: any = 'Something went wrong') {
    CoreToast.error(message, {
      // icon: <ToastImage file={ErrorImage} alt="error" />,
      autoClose: 5000,
    })
  },
  warning(message: any) {
    CoreToast.warning(message, {
      // icon: <ToastImage file={WarningImage} alt="warning" />,
      autoClose: 5000,
    })
  },
}

export {toastService}

import {createSlice} from '@reduxjs/toolkit'
import {fetchDashboard, fetchHeaders} from './APIs'

const initialState = {
  loading: false,
  data: [],
  error: '',
  headers: [],
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    clearState: (state) => {
      state.data = []
    },
    clearHeaderState: (state) => {
      state.headers = []
    },
  },
  extraReducers: {
    [fetchDashboard.pending]: (state, {payload}) => {
      return {...state, loading: true}
    },
    [fetchDashboard.fulfilled]: (state, {payload}) => {
      return {
        ...state,
        data: payload.data || [],
        loading: false,
      }
    },
    [fetchDashboard.rejected]: (state, {payload}) => {
      return {...state, data: [], loading: false}
    },
    [fetchHeaders.pending]: (state, payload) => {
      // console.log('pending')
      return {
        ...state,
      }
    },
    [fetchHeaders.fulfilled]: (state, {payload}) => {
      return {
        ...state,
        headers: payload.data,
      }
    },
    [fetchHeaders.rejected]: (state, {payload}) => {
      // console.log('rejected')
      return {...state}
    },
  },
})

export const {clearState, clearHeaderState} = dashboardSlice.actions

export default dashboardSlice.reducer

import React, {Suspense, lazy} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'

export function PrivateRoutes() {
  const NewInstallation = lazy(() => import('../pages/new-installation/NewInstallation'))
  const DashboardWrapper = lazy(() => import('../pages/dashboard/DashboardWrapper'))
  const DataScan = lazy(() => import('../pages/project-scan/Index'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Routes>
        <Route path='/dashboard' element={<DashboardWrapper />} />
        {/* <Route path='/new-installation' element={<NewInstallationModule />} /> */}
        <Route path='/new-installation' element={<NewInstallation />} />
        <Route path='/project-scan' element={<DataScan />} />
        <Route path='/auth' element={<Navigate to={'/dashboard'} />} />
        <Route path='/auth/login' element={<Navigate to='/dashboard' />} />
        <Route path='/' element={<Navigate to='/dashboard' />} />
        <Route element={<Navigate to='/error/404' />} />
      </Routes>
    </Suspense>
  )
}

import React from 'react'
import {Outlet} from 'react-router-dom'

import {AsideDefault} from './components/aside/AsideDefault'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {Content} from './components/Content'
import {MasterInit} from './MasterInit'
import {PageDataProvider} from './core'
import {DrawerMessenger, ActivityDrawer, Main, InviteUsers, UpgradePlan} from '../partials'
import useWindowDimensions from '../helpers/useWindowDimensions'

const MasterLayout: React.FC = () => {
  const {width, height} = useWindowDimensions()
  // let sidebarCollapse: Boolean = window.innerWidth <= 991

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />
          {/* <ProgressBar label={`${syncLoading}%`} className={``} variant='danger' now={15} /> */}
          <div
            // style={{height: 'calc(100% - var(--kt-toolbar-height) - 200px'}}
            id='kt_content'
            className='content d-flex flex-column flex-column-fluid'
          >
            <Toolbar />
            {/* <div className='post d-flex flex-column-fluid' id='kt_post'> */}
            {/* <Content> */}
            <div style={{padding: `10px 30px`, minHeight: '100%'}} className='overflow-hidden'>
              <Outlet />
              {/* </div> */}
              {/* </Content> */}
            </div>
          </div>
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <Main />
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}

      <MasterInit />
    </PageDataProvider>
  )
}

export {MasterLayout}

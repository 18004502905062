import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  loading: false,
  data: [],
  error: '',
}

export const baseApi = axios.create({
  baseURL: 'http://192.168.225.69:9000',
  //  baseURL :' http://192.168.104.211:9000'
})
export const loginData = createAsyncThunk('login/loginData', async (values) => {
  const response = await baseApi.post(`/login`, {
    userName: values.userName,
    password: values.password,
  })
  return response.data
})

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},
  extraReducers: {
    [loginData.pending]: (state, {payload}) => {
      return {...state, loading: true}
    },
    [loginData.fulfilled]: (state, {payload}) => {
      return {...state, data: [...payload.data], loading: false}
    },
    [loginData.rejected]: (state, {payload}) => {
      // return {...state, error: payload.message, loading: false}
    },
  },
})
// export const { removeItems } = newInstallationSlice.actions
export default loginSlice.reducer

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import { Outlet} from 'react-router-dom'
import './AuthPage.css'

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundColor: `white`,
      }}
    >
      {/* begin::Content */}
      <div className=' form d-flex flex-center flex-row-fluid '>
        {/* begin::Logo */}
        {/* <a href='#123' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-1.svg')} className='h-45px' />
        </a> */}
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='container-fluid'>
          <div className='d-flex aling-items-center flex-column mb-10 '>
            <div className='  d-flex justify-content-center w-100 '>
              <img
                style={{width:'60%'}}
                // className='w-75'
                src='https://pclawtimematters.com/img/png/logo-primary-rgb.png'
                alt='PCLaw|Time Matters'
              />
            </div>
              <Outlet/>
          </div>
        </div>
        <div className='login-logo-container'>
          <img
            className='login-logo '
            alt='Logo'
            src={'https://pclawtimematters.com/img/jpg/home-hero-mobile.jpg'}
          />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      {/* <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a href='#' className='text-muted text-hover-primary px-2'>
            About
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact Us
          </a>
        </div>
      </div> */}
      {/* end::Footer */}
    </div>
  )
}

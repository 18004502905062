import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'
import NewInstallationReducer from '../../app/pages/new-installation/redux/NewInstallationSlice'
import DashboardReducer from '../../app/pages/dashboard/redux/dashboardSlice'

import loginReducer from '../../app/modules/auth/redux/login/login'
import projectScanReducer from '../../app/pages/project-scan/redux/projectScan'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  newInstallations: NewInstallationReducer,
  dashboard: DashboardReducer,
  dataScan: projectScanReducer,
  login: loginReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
}

import {toAbsoluteUrl} from '../../../helpers'

export function FallbackView() {
  return (
    <div className='splash-screen overflow-hidden'>
      <img
        className='w-25px logo'
        src={toAbsoluteUrl('https://pclawtimematters.com/favicon.ico')}
        alt='Start logo'
      />
      <span>Loading ...</span>
    </div>
  )
}

import React, {Suspense} from 'react'
import {HashRouter} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {Toast} from './Components/Toast'
import AuthInit from './modules/auth/redux/AuthInit'
import {RoutesMain} from './routing/Routes'
import './style.css'

type Props = {
  basename: string
}

const App: React.FC<Props> = ({basename}) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <HashRouter basename={basename}>
        <Toast />
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <RoutesMain />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </HashRouter>
    </Suspense>
  )
}

export {App}
